import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import url from 'url'
import config from '../../../utils/siteConfig'

const MetaData = ({ title, description, image, location }) => {
    const canonical = url.resolve(config.siteUrl, location.pathname)
    const finalTitle = title || config.siteTitleMeta || 'Open Racer'
    const finalDescription = description || config.siteDescriptionMeta || 'Getting Faster. On and to Race Tracks.'
    const finalImage = image ? url.resolve(config.siteUrl, image) : null

    return (
        <Helmet>
            <title>{finalTitle}</title>
            <meta name="description" content={finalDescription} />
            <link rel="canonical" href={canonical} />
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />

            <meta property="og:site_name" content={finalTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={finalTitle} />
            <meta property="og:description" content={finalDescription} />
            <meta property="og:url" content={canonical} />
            {finalImage && <meta property="og:image" content={finalImage} />}

            <meta name="twitter:title" content={finalTitle} />
            <meta name="twitter:description" content={finalDescription} />
            <meta name="twitter:url" content={canonical} />
            {finalImage && <meta name="twitter:image" content={finalImage} />}
        </Helmet>
    )
}

MetaData.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default MetaData
